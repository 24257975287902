import $ from 'jquery';
import jQuery from 'jquery';

$(document).ready(function()
	{
	$('.bizthumbnail').showLargeImage();
	$('.moreinfo').hideInfo();
	$('input[type="tel"]').formatPhone();
	});

// avatar loader
$(document).ready(function() {

    var readURL = function(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $('.profile-pic').attr('src', e.target.result);
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    $(".file-upload").on('change', function(){
        readURL(this);
    });

    $(".upload-button").on('click', function() {
       $(".file-upload").click();
    });
});

// comment form inner opener
//$("#comment_button").click(function(){
//        $(".form_inner").show('slow');
//   });
//$("#comment_text").click(function(){
//        $(".form_inner").show('slow');
//    });

// read more
$(document).ready(function() {
  $("#toggle").click(function() {
    var elem = $("#toggle").text();
    if (elem == "Read More") {
      //Stuff to do when btn is in the read more state
      $("#toggle").text("Read Less");
      $("#text").slideDown();
    } else {
      //Stuff to do when btn is in the read less state
      $("#toggle").text("Read More");
      $("#text").slideUp();
    }
  });
});

// hidefield toggle
$(function()
	{
	$('.hidefield').each(function()
		{
		var ctrl = $(this).find('input:last');
		var targetid = ctrl.attr('id');
		var target = $('.'+targetid);
		ctrl.click(function()
			{
			target.slideToggle('slow', function()
				{
				});
			return true;
			});
		if ( ! ctrl.is(':checked'))
			target.hide();
		});
	});
/*
$(function()
    {
    $('.hidefield').each(function()
        {
        var grp = $(this).data('target');
       	$(this).find('input').each(function()
        	{
					var targetid = $(this).attr('id');
					var target = $('.'+targetid);
					$(this).change(function()
							{
							$('.'+grp).hide();
							target.show('slow');
							return true;
							});
					if ( ! $(this).is(':checked'))
						 target.hide();
					});
				});
    });
*/

$(function()
	{
	$('.toggle-view li').click(function ()
		{
		var text = $(this).children('div.ans');
		if (text.is(':hidden'))
			{
			text.slideDown('200');
			$(this).children('span').html('-');
			}
		else
			{
			text.slideUp('200');
			$(this).children('span').html('+');
			}
    });
	});

$(function()
	{
	$('a[href*="#"]').not('.top-bar a').not('.accordion a').not('.tabs a').not('.orbit-container a').not('.reveal-link').not('.clearing-assembled a').click(function()
		{
		var adjustOffset = 40;
		//var adjustOffset = 0;
		if (this.hash.length == 0)
			{
			var targetOffset = - adjustOffset;
			$('html,body').animate({scrollTop: targetOffset}, 1000);
			return false;
			}
		var $target = $(this.hash);
		$target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
		if ($target.length)
			{
			var targetOffset = $target.offset().top - adjustOffset;
			$('html,body').animate({scrollTop: targetOffset}, 1000);
			return false;
			}
		});
	});

// moreinfo
(function($)
	{
	$.fn.hideInfo = function()
		{
		return this.each(function()
			{
			var self = $(this);
			var showtext = 'Read more...';
			var hidetext = 'Read less...';
			var title = self.attr('title');
			if (title)
				{
				showtext = 'Show ' + title;
				hidetext = 'Hide ' + title;
				}
			var lnk = $('<a class="minfo" href="#">' + showtext + '</a>');
			lnk.click(function()
				{
			  self.slideToggle('slow', function()
					{
					if ($(this).is(":visible"))
						lnk.text(hidetext);
			    else
						lnk.text(showtext);
					//setTall();
			  	});
				return false;
				});
			var p = $('<p/>');
			p.append(lnk);
			p.insertAfter(self);
			self.hide();
			});
		}
	})(jQuery);

(function($)
	{
	$.fn.showLargeImage = function()
		{
		$(document).keypress(function(e)
			{
			if(e.keyCode==27)
				removeLightbox();
			});
		return this.each(function()
			{
			var a = $('<a class="lightbox"/>');
			var src = $(this).attr('src');
			var dot = src.lastIndexOf('.');
			src = src.substr(0, dot) + '-large' + src.substr(dot);
			a.attr('href', src);
			a.click(function(e)
				{
				$('body').css('overflow-y', 'hidden');

				$('<div id="overlay"></div>')
					.css('top', $(document).scrollTop())
					.css('opacity', '0')
					.animate({'opacity': '0.5'}, 'slow')
					.appendTo('body');

				$('<div id="lightbox"></div>')
					.hide()
					.appendTo('body');

				$('<img title="Click here to close"/>')
					.attr('src', $(this).attr('href'))
					.on('load', function()
						{
						positionLightboxImage();
						})
					.on('click', function()
						{
						removeLightbox();
						})
					.appendTo('#lightbox');

				return false;
				});
			$(this).wrap(a);
			});
		function positionLightboxImage()
			{
			var top = ($(window).height() - $('#lightbox').height()) / 2;
			var left = ($(window).width() - $('#lightbox').width()) / 2;
			var closebutton = $('<img src="/images/close.png" width="30" height="30" alt="close" style="position: absolute; top: 0px; right: 0px;"/>');
			closebutton.click(function()
				{
				removeLightbox();
				});
			$('#lightbox')
				.append(closebutton)
				.css({'top': top + $(document).scrollTop(), 'left': left})
				.fadeIn();
			}
		function removeLightbox()
			{
			$('#overlay, #lightbox')
				.fadeOut('slow', function()
					{
					$(this).remove();
					$('body').css('overflow-y', 'auto');
					});
			}
		}
	})(jQuery);

(function($)
{
	$.fn.formatPhone = function()
	{
		var value;
		var maxlength;
		this.each(function()
		{
			var $el = $(this);
			var ctrl = false;
			var shift = false;
			$el.on('keydown', function(e)
			{
				var key = e.charCode || e.keyCode || 0;
				var $text = $(this);
				value = $text.val();
				var gaps = getGaps();;
				if (key !== 8 && key !== 9)
				{
					if ($.inArray(value.length, gaps) != -1)
						$text.val(value + ' ');
				}
				if (key == 17 || key == 91)
				  ctrl = true;
				if (key == 16)
				  shift = true;
				return (key == 8 || key == 9 || key == 17 || key == 46 || key == 91 || (key >= 37 && key <= 40) || ( ! shift && key >= 48 && key <= 57) || ( ! shift && key >= 96 && key <= 105)
				        || (ctrl && key == 67) || (ctrl && key == 86) || (shift && key == 61));
			});

			$el.on('keyup', function(e)
			{
				ctrl = false;
				shift = false;
			});
			
			$el.on('input', function(e)
			{
				var $text = $(this);
				value = $text.val();
				var len = value.length;
				value = value.replace(/[^\+0-9]/g, '');
				var gaps = getGaps();
				for (const gap of gaps)
				{
					if (value.length > gap && value.charAt(gap) != ' ')
						value = value.substring(0, gap) + ' ' + value.substring(gap, value.length);
				}
				if (value.length > maxlength)
					value = value.substring(0, maxlength);
				if (maxlength == 14 && value.length == 14)
					value = '+' + value;
				if (value.length != len)
					$text.val(value);
			});
		});
		return this;
		function getGaps()
		{
			var gaps = [2, 7];
			maxlength = 12;
			if (value.startsWith('04') || value.startsWith('05') || value.startsWith('13') || value.startsWith('18'))
				gaps = [4, 8];
			else
			if (value.startsWith('+614') || value.startsWith('+615') || value.startsWith('+61 4') || value.startsWith('+61 5'))
			{
				gaps = [3, 7, 11];
				maxlength = 15;
			}
			else
			if (value.startsWith('+6'))
			{
				gaps = [3, 5, 10];
				maxlength = 15;
			}
			else
			if (value.startsWith('614') || value.startsWith('615') || value.startsWith('61 4') || value.startsWith('61 5'))
			{
				gaps = [2, 6, 10];
				maxlength = 14;
			}
			else
			if (value.startsWith('61'))
			{
				gaps = [2, 4, 9];
				maxlength = 14;
			}
			return gaps;
		}
	}
})(jQuery);
