// addressfinder.com.au

import $ from 'jquery';
import jQuery from 'jquery';

function fieldVerified($el, required, parent)
{
	if ( ! required)
		return true;
	if ($el.data('verified') == null && $el.val() != '') // assume filled from db
		return true;
	return ($el.data('verified') == 't');
};
Foundation.Abide.defaults.validators['verified'] = fieldVerified;

$(function()
{
	var key = '6PYVBQ3MXJ4NGELKT8CF';

	if ($('input[name="email_address"]').length)
	{
		var confEmail =
		{
			addressSelector: "input[name=email_address]",
			licenceKey: key,
			rules:
			{
				unverified:
				{
					rule: "block"
				},
				public:
				{
					rule: "warn",
					message: "Emails to/from public email addresses like abc@gmail.com may be auto scanned by your mail service. Do you want to use another email address?"
				}
			}
		};
	
		var e = document.createElement("script");
		e.src="https://api.addressfinder.io/assets/email/v1/widget.js",
		e.async=!0,
		e.onload=function()
		{
			new AddressFinder.Email.Widget(confEmail.addressSelector, confEmail.licenceKey, confEmail)
			.on('result:verified', function(metadata)
			{
				var $field = $(confEmail.addressSelector);
				var $errorField = $('#'+$field.attr('aria-describedby'));
				var $label = $field.parent('label');
				$field.removeClass('is-invalid-input');
				$field.removeAttr('data-invalid');
				$field.removeAttr('aria-invalid');
				$field.data('verified', 't');
				$errorField.removeClass('is-visible');
				$label.removeClass('is-invalid-label');
			})
			.on('result:not_verified', function(metadata)
			{
				var $field = $(confEmail.addressSelector);
				var $errorField = $('#'+$field.attr('aria-describedby'));
				var $label = $field.parent('label');
				$field.addClass('is-invalid-input');
				$field.attr('data-invalid', '');
				$field.attr('aria-invalid', 'true');
				$field.data('verified', 'f');
				$errorField.addClass('is-visible');
				$errorField.text('Email not verified: '+metadata.not_verified_reason);
				$label.addClass('is-invalid-label');
			});
		},
		document.body.appendChild(e);
	}

	if ($('input[name="phone_number"]').length)
	{
		var confPhone =
		{
			phoneInputSelector: "input[name=phone_number]",
			licenceKey: key,
			defaultCountryCode: "AU",
			allowedCountryCodes: "AU",
			rules:
			{
				unverified:
				{
					rule: "block",
					message: "Please provide a valid phone number"
				}
			}
		};
	
		var e = document.createElement("script");
		e.src="https://api.addressfinder.io/assets/phone/v2/widget.js",
		e.async=!0,
		e.onload=function()
		{
			new AddressfinderPhone.Phone.Widget(confPhone.phoneInputSelector, confPhone.licenceKey, confPhone)
			.on('result:verified', function(metadata)
			{
				var $field = $(confPhone.phoneInputSelector);
				console.log('phoneInputSelector contains '+$field.val());
				var $errorField = $('#'+$field.attr('aria-describedby'));
				var $label = $field.parent('label');
				console.log('error contains '+$errorField.text());
				$field.removeClass('is-invalid-input');
				$field.removeAttr('data-invalid');
				$field.removeAttr('aria-invalid');
				$field.data('verified', 't');
				$errorField.removeClass('is-visible');
				$label.removeClass('is-invalid-label');
				console.log('Phone verified');
			})
			.on('result:not_verified', function(metadata)
			{
				var $field = $(confPhone.phoneInputSelector);
				console.log('phoneInputSelector contains '+$field.val());
				var $errorField = $('#'+$field.attr('aria-describedby'));
				var $label = $field.parent('label');
				console.log('error contains '+$errorField.text());
				$field.addClass('is-invalid-input');
				$field.attr('data-invalid', '');
				$field.attr('aria-invalid', 'true');
				$field.data('verified', 'f');
				$errorField.addClass('is-visible');
				$errorField.text('Phone not verified: '+metadata.not_verified_reason);
				$label.addClass('is-invalid-label');
				console.log('Phone not verified: '+metadata.not_verified_reason);
			});
		},
		document.body.appendChild(e);
	}

	if ($('input[name="address_line"]').length)
	{
		var afBilling
		var initAFBilling = function()
		{
			afBilling = new AddressFinder.Widget(
				$('input[name="address_line"]')[0],
				key,
				'AU',
				{
					"address_params":
					{
						"source": "gnaf,paf"
					}
				});
	
			afBilling.on("result:select", function(fullAddress, metaData)
			{
				$('input[name="address_line"]').val(metaData.address_line_1);
				$('input[name="address_line_2"]').val(metaData.address_line_2);
				$('input[name="address_locality"]').val(metaData.locality_name);
				$('input[name="address_state"]').val(metaData.state_territory);
				$('input[name="address_postcode"]').val(metaData.postcode);
				$('input[name="address_line"]').closest('form').foundation('validateForm');
			});
		};
		
		$( document ).ready(function()
		{
			$.getScript('https://api.addressfinder.io/assets/v3/widget.js', initAFBilling);
		});
	}
	if ($('input[name="delivery_address_line"]').length)
	{
		var afDelivery
		var initAFDelivery = function()
		{
			afDelivery = new AddressFinder.Widget(
				$('input[name="delivery_address_line"]')[0],
				key,
				'AU',
				{
					"address_params":
					{
						"post_box": "0",
						"source": "gnaf,paf"
					}
				});

	
			afDelivery.on("result:select", function(fullAddress, metaData)
			{
				$('input[name="delivery_address_line"]').val(metaData.address_line_1);
				$('input[name="delivery_address_line_2"]').val(metaData.address_line_2);
				$('input[name="delivery_address_locality"]').val(metaData.locality_name);
				$('input[name="delivery_address_state"]').val(metaData.state_territory);
				$('input[name="delivery_address_postcode"]').val(metaData.postcode);
				$('input[name="delivery_address_line"]').closest('form').foundation('validateForm');
			});
		};
		
		$( document ).ready(function()
		{
			$.getScript('https://api.addressfinder.io/assets/v3/widget.js', initAFDelivery);
		});
	}
//})();
});
