import $ from 'jquery';
import jQuery from 'jquery';

Foundation.Abide.defaults.validators['required_visible'] = function(el,required,parent)
	{
	return ! el.is(':visible') || ( el.is(':visible') && el.val() != "" );
	}

$(function()
	{
	function showForm(type)
		{
		if (type == 'credit')
			$('.bizCreditPayment').show('slow');
		else
			$('.bizCreditPayment').hide('slow');
		if ( ! type)
			$('.bizSubmitPayment').hide('slow');
		else
			$('.bizSubmitPayment').show('slow');
		}
	
	$('.bizPaymentOption').on('click', function()
		{
		showForm($(this).data('type'));
		});

	console.log('type='+$('.bizPaymentOption:checked').data('type'));
	showForm($('.bizPaymentOption:checked').data('type'));
	});
 

